<template>
    <div class="mt-4">
        <v-row>
            <v-col cols="12" md="6">
                <v-select v-model="selectedtype" dense outlined hide-details label="Type" :items="enterprise_type"
                    item-value="id" item-text="name" return-object></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select v-model="selectedform" dense outlined hide-details label="Category" :items="enterprise_form"
                    item-value="id" item-text="name" return-object></v-select>
            </v-col>
        </v-row>
        <!-- {{selectedtype}} -->
        <div class="my-3">
            <label for="">About company</label>
            <v-textarea v-model="about" :value="about" hide-details dense outlined place-holder="About company">
            </v-textarea>
        </div>
        <v-card-actions class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
                Unsave
            </v-btn>
            <v-btn color="primary" class="mt-4" :loading="loading" @click.prevent="saveAboutInformations">
                update
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    inject: {
        theme: {
            default: { isDark: false },
        },
    },
    props: {
        company: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            loading: false,
            selectedtype: {},
            selectedform: {},
            enterprise_type: [],
            enterprise_form: [],
            about: this.company.about
        }
    },
    methods: {
        resetForm() {
            this.about = this.company.about
        },
        saveAboutInformations() {
            this.loading = true;
            var bodyFormData = {
                "enterprise": {
                    'typenterprise.id': this.selectedtype.id,
                    'enterprise_form.id': this.selectedform.id,
                    about: this.about,
                }
            }
            this.loading = true;
            Drequest.api(`enterprise.update?id=${this.$store.getters.getCompany.id}`)
                .data(bodyFormData)
                .raw((response) => {
                    if (response.success === true) {
                        this.loading = false;

                        this.$fire({
                            type: "success",
                            text: 'Successfull updated',
                            timer: 3000
                        })
                    }
                    else {
                        this.loading = false;
                        this.$fire({
                            type: "error",
                            text: 'Error during updated',
                            timer: 3000
                        })
                    }
                }).catch((err) => {
                    this.loading = false;
                });
        },

        getCompanyType() {
            // Drequest.api(`tree-item.lazyloading?dfilters=on&tree.name:eq=enterprise_type`).get((response) => {
            Drequest.api(`lazyloading.typenterprise?dfilters=on`).get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach(element => {
                        this.enterprise_type.push({ id: element.id, name: element.designation })
                    });
                    this.selectedtype = {
                        id: this.company.type.id,
                        name: this.company.type.designation
                    }
                }
            }).catch((err) => { });

            // Drequest.api(`tree-item.lazyloading?dfilters=on&tree.name:eq=enterprise_type`).get((response) => {
            //     if (response.success === true) {
            //         response.listEntity.forEach(element => {
            //             this.enterprise_type.push({ id: element.id, name: element.name.en })
            //         });
            //         this.selectedtype = {
            //             id: this.company.enterprise_type.id,
            //             name: this.company.enterprise_type.name
            //         }
            //     }
            // }).catch((err) => { });

            Drequest.api(`tree-item.lazyloading?dfilters=on&tree.name:eq=enterprise_form`).get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach(element => {
                        this.enterprise_form.push({ id: element.id, name: element.name.en })
                    });
                    this.selectedform = {
                        id: this.company.enterprise_form.id,
                        name: this.company.enterprise_form.name
                    }
                }
            }).catch((err) => { });
        },
    },
    beforeMount() {
        this.getCompanyType()
    },
}
</script>