<template>
  <v-card flat outlined class="my-2">
    <v-card-title>Generals</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="name" :value="name" label="Name" dense outlined>
            </v-text-field>
            <v-text-field class="mt-3" v-model="phonenumber" :value="phonenumber" label="Phone number" dense outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="email" :value="email" label="E-mail" dense outlined>
            </v-text-field>
            <v-text-field class="mt-3" v-model="website" :value="website" label="Website" dense outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pt-1">
            <v-textarea v-model="address" :value="address" label="Adress" hide-details dense outlined>
            </v-textarea>
          </v-col>
          <v-col cols="12" class="pt-1">
            <label for="">About company</label>
            <v-textarea v-model="about" :value="about" hide-details dense outlined>
            </v-textarea>
          </v-col>
          <v-col cols="12" class="d-flex flex-wrap">
            <v-spacer></v-spacer>
            <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
              Unsave
            </v-btn>
            <v-btn color="primary" class="mt-4" :loading="loading" @click.prevent="updateBasicUserInformations">
              update
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
  props: {
    company: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      loading: false,

      name: this.company.name,
      phonenumber: this.company.telephone,
      email: this.company.email,
      address: this.company.address,
      about: this.company.about,
      website: this.company.website,
    }
  },
  methods: {
    updateBasicUserInformations() {

      this.$emit('updateBasicInfos', {
        'enterprise': {
          'name': this.name,
          'email': this.email,
          'telephone': this.phonenumber,
          'password': this.password,
          'address': this.address,
          'website': this.website,
        }
      })

      this.loading = true;
      var bodyFormData = {
        'enterprise': {
          'name': this.name,
          'email': this.email,
          'telephone': this.phonenumber,
          'password': this.password,
          'address': this.address,
          'website': this.website,
        }
      }
      this.loading = true;
      Drequest.api(`enterprise.update?id=${this.$store.getters.getCompany.id}`)
        .data(bodyFormData)
        .raw((response) => {
          if (response.success === true) {
            this.loading = false;
            this.$fire({
              type: "success",
              text: 'Successfull updated',
              timer: 3000
            })
          } else {
            this.$fire({
              type: "error",
              text: 'Error during updated',
              timer: 3000
            })
            this.loading = false;
          }
        }).catch((err) => {
          window.console.log(err)
          this.loading = false;
        });
    },

    resetForm() {
      this.name = this.company.name
      this.phonenumber = this.company.telephone
      this.email = this.company.email
      this.address = this.company.address
      this.about = this.company.about
      this.website = this.company.website
      this.country = this.company.country
    },
  },
  setup(props) {
    var icons = {
      mdiAlertOutline,
      mdiCloudUploadOutline,
    }
    return {
      icons
    }
  },
}
</script>
