<template>
  <v-card flat class="mt-2">
    <div v-if="loading">
      <!-- <setting-basic @updateBasicInfos="updateBasicUserInfos($event)" :user="this.$store.getters.getUser">
          </setting-basic> -->

      <v-card flat outlined class="my-2">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader class="mx-auto" type="article, article, actions"></v-skeleton-loader>
        </v-sheet>
      </v-card>
    </div>
    <div v-else>
      <setting-basic :company="enterprise">
      </setting-basic>

      <v-divider></v-divider>

      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>About {{ enterprise.name }}</v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content>
            <setting-advance :company="enterprise"></setting-advance>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Other</v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

  </v-card>
</template>

<script>
import { mdiPlus, mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import SettingBasic from './settings/SettingBasic.vue'
import SettingAdvance from './settings/SettingAdvance.vue'
import { Drequest } from '@/plugins/Drequest'

export default {
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data() {
    return {
      loading: true,
      enterprise: {},
      panel: [1, 2, 3]
    }
  },
  components: {
    SettingBasic,
    SettingAdvance,
  },
  methods: {
    fetchCompanyInformation() {
      this.loading = true
      Drequest.api(`enterprise.fetch?keyid=${this.$store.getters.getCompany.id}`).get((response) => {
        if (response.success === true) {
          this.enterprise = response.enterprise
          this.loading = false
        }
        else { this.loading = false }
      }).catch((err) => { this.loading = false });
    },
  },
  beforeMount() {
    this.fetchCompanyInformation()
  },
  setup() {

    var icons = {
      mdiAlertOutline,
      mdiCloudUploadOutline,
      mdiPlus,
    }
    return {
      icons,
    }
  },
}
</script>
